
html, body, #root {
	height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-nav-svg {
	width: 24px;
	height: 24px;
}

.modal-content-nb, .modal-content-nb > div {
	border: none !important;
}

.card-subtitle {
	color: #888;
}

[data-dragging="true"] {
	opacity: 0.3;
}

[data-dragging="false"] {
	opacity: 1;
}

[data-overflow-auto="true"] {
	overflow: auto;
}
