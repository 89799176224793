#root {

	--color-content		: #fff;
	--color-content2	: #f0f2f5;
	--color-content3	: #fff;
	--color-header		: #fff;
	--color-header2		: #f0f2f5;
	--color-border		: #d5d6d7;
	--color-border2		: #e2e4e7;
	--color-text		: #212529;
	--color-text2		: #495057;
	--color-text-label	: #888;
	--color-fill		: #495057;
	--color-hover		: #f0f2f5;

}


