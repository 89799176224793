.App {
	color: #495057;
	fill: #495057;
}

.App, .App-content-bottom {
  height:100%;
  overflow: hidden;
}


.App, .App-sidebar, .App-content,
.App-sidebar-bottom, .App-sidebar-subsection-list > li {
	display: flex;
}

.App-sidebar, .App-content, .App-sidebar-bottom {
	flex-direction: column;
	overflow: hidden;
}

.App-Sidebar, .App-sidebar-top, .App-content-top {
	flex: 0 0 auto;
}

.App-content, .App-sidebar-bottom, .App-content-bottom {
	flex: 1 1 auto;
}

.App-sidebar[data-state-open="false"] {
	width: 0px;
	overflow: hidden;
}

.App-sidebar[data-state-open="true"] {
	width: 300px;
	min-width: 300px;
	overflow: auto;
}

.App-sidebar-top, .App-content-top, .App-sidebar-bottom {
	border: 0px dashed var(--color-border);
}

.App-sidebar-top, .App-content-top {
	border-bottom-width: 1px;
}

.App-sidebar-top > .navbar > .navbar-nav > .navbar-brand,
.App-content-top > .navbar > .navbar-nav > .navbar-brand
{
	cursor: pointer;
}

.App-sidebar-bottom {
	border-right-width: 1px;
	overflow: auto;
	background-color: var(--color-content2);
}




/* --------------------------------------------------------------- */

.App-sidebar-ul-main {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.App-sidebar-ul-main li {
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 22px;
}

.App-sidebar-ul-main li:hover {
	border: 1px dashed var(--color-border);
	border-right-color: transparent;
	border-left-color: transparent;
	border-radius: 4px;
}

.App-sidebar-ul-main li:first-child:hover {
	border-top-color: transparent;
}


.App-sidebar-subsection-list > li,
.App-sidebar-ul-main li
{
	border: 1px dashed transparent;
	cursor: pointer;
	font-size: 19px;
}

.App-sidebar-subsection-header {
	display: flex;
	padding: 4px 22px;
	cursor: pointer;
	flex: 0 0 auto;
	border: 0px dashed var(--color-border);
	border-bottom-width: 1px;
}

.App-sidebar-subsection-header > div:first-child {
	flex: 0 0 auto;
	padding-top: 3px;
	padding-bottom: 5px;
	padding-right: 5px;
}

.App-sidebar-subsection-header > div:nth-child(2) {
	flex: 1 1 auto;
	text-align: center;
	padding-top: 2px;
	font-size: 19px;
}

.App-sidebar-subsection-header > div:last-child {
	flex: 0 0 auto;
	padding-left: 10px;
	padding-right: 5px;
	padding-top: 3px;
	padding-bottom: 5px;
}

.App-sidebar-subsection-header > div:first-child > svg,
.App-sidebar-subsection-header > div:last-child > svg
{
	width: 16px;
	height: 16px;
	/* fill: #aaa; */
}

/* .App-sidebar-subsection-header > div:last-child > svg */
/* { */
/* 	fill: #bbb; */
/* } */

.App-sidebar-subsection-list {
	flex: 1 1 auto;
	overflow: auto;
	margin: 0;
	padding: 0;
	fill: transparent;
	list-style-type: none;
}

.App-sidebar-subsection-list > li {
	padding-left: 22px;
	padding-top: 3px;
	padding-bottom: 3px;
	/* border: 1px dashed transparent; */
	/* border-radius: 4px; */
	/* font-size: 20px; */
}

.App-sidebar-subsection-list > li:hover
{
	border-color: var(--color-border);
	/* border: 1px dashed #d5d6d7; */
	/* border-radius: 4px; */
	border-right-color: transparent;
	border-left-color: transparent;
}

.App-sidebar-subsection-list > li:first-child:hover
{
	/* border: 1px dashed #d5d6d7; */
	/* border-radius: 4px; */
	border-top-color: transparent;
	/* border-right-color: transparent; */
	/* border-left-color: transparent; */
}

.App-sidebar-subsection-list > li[data-selected="true"]
{
	/* fill: #808080; */
	background-color: #f0f2f5;
	/* background-image: linear-gradient(to right, transparent, #f0f2f5); */
	border-right-color: #007bff;
	border-right-width: 2px;
	border-right-style: solid;
}


.App-sidebar-subsection-list > li > div:nth-child(1) {
	flex: 1 1 auto;
	fill: #808080;
	cursor: pointer;
}

.App-sidebar-subsection-list > li > div:nth-child(2) {
	padding-left: 5px;
	padding-right: 5px;
}

.App-sidebar-subsection-list > li > div:first-child > svg
{
	width: 10px;
	height: 10px;
}

.App-sidebar-subsection-list > li > div:last-child > svg
{
	width: 16px;
	height: 16px;
}

.App-sidebar-subsection-list > li > div:last-child > svg:hover {
	fill: #0055ff;
	cursor: pointer;
}

.App-sidebar-subsection-form {
	padding: 0px 10px;
}

.App-sidebar-subsection-form-buttons
{
	padding: 10px 10px;
}

.App-sidebar-subsection-form > input,
.App-sidebar-subsection-form > div
{
	margin: 15px 0px;
}

.App-sidebar-subsection-form > input {
	/* border-radius: 0.3rem; */
	/* border-style: dashed; */
	border-style: none;
	font-size: 16px;
	background-color: transparent;
	color: #495057;
	font-size: 19px;
}

.App-sidebar-subsection-form > input::placeholder {
	color: #bbb;
}

.App-sidebar-subsection-form > input:focus {
	background-color: transparent;
	outline: none;
	-webkit-box-shadow: none;
  	box-shadow: none;
	color: #495057;
}

.App-sidebar-subsection-form-buttons {
	display: flex;
}

.App-sidebar-subsection-form-buttons > button {
	flex: 1 1 auto;
	font-weight: bold;
	color: #858688;
	background-color: transparent;
	border: 1px dashed #ccc;
}

.App-sidebar-subsection-form-buttons > button:first-child {
	margin-right: 3px;
}

.App-sidebar-subsection-form-buttons > button:hover {
	color: rgb(32, 33, 36);
	border-color: transparent;
	background-color: #e2e6ea;
}

.App-sidebar-subsection-form-buttons > div {
	flex: 1 1 auto;
}

.App-sidebar-subsection-list > li > [data-drop-active="true"] {
	background-color: #dae0e5;
}

/* .App-content-bottom { */
/* 	background-color: var(--var-default-content-color); */
/* } */

.settings-container {
	display: flex;
	flex-direction: column;
	background-color: var(--color-content2);
	height: 100%;
}

.settings-container > div {
	margin: 30px 50px;
	min-width: 350px;
	background-color: var(--color-content);
	border: 1px solid var(--color-border2);
	padding: 25px;
	border-radius: 15px;
}

.settings-container > div > div > span {
	font-size: 20px;
}

/* --------------------------------------------------------------- */
/* --------------------------------------------------------------- */
/* --------------------------------------------------------------- */

@media only screen and (max-width: 600px) {

	.App-sidebar[data-state-open="true"] {
		width: 100%;
	}

	.App-content[data-state-open="true"] {
		display: none;
	}

	.settings-container > div {
		margin: 20px 5px;
		min-width: 350px;
	}


}



